<template>
  <div class="bg-gray-900">
    <div class="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
      <div class="relative bg-white shadow-xl">
        <h2 class="sr-only">Contact us</h2>

        <div class="grid grid-cols-1 lg:grid-cols-2 bg-gray-900">
          <!-- Contact information -->
          <div class="relative overflow-hidden py-10 px-6 sm:px-10 xl:p-12 shadow-lg" :class="`${bgbrand700}`">
            <div class="absolute inset-0 pointer-events-none sm:hidden" aria-hidden="true">
              <svg class="absolute inset-0 w-full h-full" width="343" height="388" viewBox="0 0 343 388" fill="none" preserveAspectRatio="xMidYMid slice" xmlns="http://www.w3.org/2000/svg">
                <path d="M-99 461.107L608.107-246l707.103 707.107-707.103 707.103L-99 461.107z" fill="url(#linear1)" fill-opacity=".1" />
                <defs>
                  <linearGradient id="linear1" x1="254.553" y1="107.554" x2="961.66" y2="814.66" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#fff" />
                    <stop offset="1" stop-color="#fff" stop-opacity="0" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
            <div class="hidden absolute top-0 right-0 bottom-0 w-1/2 pointer-events-none sm:block lg:hidden" aria-hidden="true">
              <svg class="absolute inset-0 w-full h-full" width="359" height="339" viewBox="0 0 359 339" fill="none" preserveAspectRatio="xMidYMid slice" xmlns="http://www.w3.org/2000/svg">
                <path d="M-161 382.107L546.107-325l707.103 707.107-707.103 707.103L-161 382.107z" fill="url(#linear2)" fill-opacity=".1" />
                <defs>
                  <linearGradient id="linear2" x1="192.553" y1="28.553" x2="899.66" y2="735.66" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#fff" />
                    <stop offset="1" stop-color="#fff" stop-opacity="0" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
            <div class="hidden absolute top-0 right-0 bottom-0 w-1/2 pointer-events-none lg:block" aria-hidden="true">
              <svg class="absolute inset-0 w-full h-full" width="160" height="678" viewBox="0 0 160 678" fill="none" preserveAspectRatio="xMidYMid slice" xmlns="http://www.w3.org/2000/svg">
                <path d="M-161 679.107L546.107-28l707.103 707.107-707.103 707.103L-161 679.107z" fill="url(#linear3)" fill-opacity=".1" />
                <defs>
                  <linearGradient id="linear3" x1="192.553" y1="325.553" x2="899.66" y2="1032.66" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#fff" />
                    <stop offset="1" stop-color="#fff" stop-opacity="0" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
            <!-- Contact Info -->
            <h3 class="text-lg font-medium text-white">Contact information</h3>
            <p class="mt-6 text-base text-indigo-50 max-w-3xl">There's a couple different ways you can reach out to us. The fastest and easiest is to text us!
            </p>
            <div class="grid md:grid-cols-2 lg:grid-cols-1 text-center">
              <div>
                <dl class="mt-8 space-y-6">
                  <a href="tel:+15873140101">
                    <dt><span class="sr-only">Phone number</span></dt>
                    <dd class="flex text-base " :class="`${textbrand50}`">
                        
                      <PhoneIcon class="flex-shrink-0 w-6 h-6 " :class="`${textbrand50}`" aria-hidden="true" />
                      <span class="ml-3">{{number}}</span>
                    </dd>
                  </a>
                  <dt><span class="sr-only">Email</span></dt>
                  <dd class="flex text-base " :class="`${textbrand50}`">
                    <MailIcon class="flex-shrink-0 w-6 h-6 " :class="`${textbrand50}`" aria-hidden="true" />
                    <span class="ml-3">{{email}}</span>
                  </dd>
                  <dt><span class="sr-only">Address</span></dt>
                  <dd class="flex text-base " :class="`${textbrand50}`">
                    <a class="flex" target="_blank" href="https://www.google.com/maps/dir//51.1799491,-114.1211909/@51.1799282,-114.1912305,12z">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                  </svg>
                    <span class="ml-2">{{address}}</span>
                    </a>
                    
                  </dd>

                </dl>
              </div>
              <div class="mt-8 space-y-0" :class="`${textbrand50}`">
                <div v-for="day in hours" :key="day">
                  <p>{{ day }}</p>
                </div>
              </div>
            </div>
          </div>
          
              <div class="mx-auto">
                <a href="https://www.google.com/maps/dir//51.1799491,-114.1211909/@51.1799282,-114.1912305,12z" target="_blank">
                  <img class="mx-auto object-cover" src="https://firebasestorage.googleapis.com/v0/b/s3-tint.appspot.com/o/S3%20Assets%2Fm_Map%20image.jpg?alt=media&token=79435c47-25f9-4515-a3b7-d69b473fad3c" alt="{{company}} map" loading="lazy">
                </a>
                
              </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MailIcon, PhoneIcon } from '@heroicons/vue/outline'
import configSetup from '@/setup.js'



export default {
  setup(){
    const {theme, textbrand50, number, email, address, mapLink, hours, bgbrand700} = configSetup()
 

    return {
      hours,
      theme,
      textbrand50,
      number, 
      email, 
      address, 
      mapLink,
      bgbrand700
    }
  },
  components: {
    MailIcon,
    PhoneIcon,
  },
}
</script>