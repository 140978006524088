<template>
    <div class="bg-gray-50">
        <div class="max-w-2xl mx-auto px-4 py-24 sm:px-6 sm:py-32 lg:max-w-7xl lg:px-8">
            <!-- Details section -->
            <section aria-labelledby="details-heading">
                <div class="flex flex-col items-center text-center">
                    <h2 id="details-heading" class="text-3xl font-extrabold tracking-tight sm:text-4xl" :class="textbrand900">Dim the lights</h2>
                    <p class="mt-3 max-w-3xl text-lg text-gray-600">Give your vehicle a more aggressive yet smooth presence on the roads and smoke out your lights.</p>
                </div>
                <div class="mt-16 grid grid-cols-1 gap-y-16 lg:grid-cols-2 lg:gap-x-8">
                    <div>
                        <div class="w-full aspect-h-2 rounded-lg overflow-hidden">
                            <img loading="lazy" src="https://firebasestorage.googleapis.com/v0/b/s3-tint.appspot.com/o/S3%20Assets%2Ftim-schmidbauer-gJFaYN37KXM-unsplash.jpg?alt=media&token=26a00619-5b17-4acc-8f8d-8f047b48fbc0" alt="Headlight image by Tim Schmidauer" class="w-full h-full object-center object-cover" />
                        </div>
                    </div>
                    <div>
                        <div class="w-full aspect-w-2 aspect-h-2 rounded-lg overflow-hidden">
                            <img loading="lazy" src="https://firebasestorage.googleapis.com/v0/b/s3-tint.appspot.com/o/S3%20Assets%2Ftim-schmidbauer-B5sZXmMP5Aw-unsplash.jpg?alt=media&token=5640a500-53b9-4ac0-821e-ba2e23eaeb7c" alt="Taillight image by Tim Schmidauer" class="w-full h-full object-center object-cover" />
                        </div>
                    </div>
                </div>
            </section>
            <div class="bg-gray-50">
                <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-24 lg:px-8 lg:flex lg:items-center lg:justify-between">
                    <h2 class="text-3xl font-extrabold tracking-tight text-gray-900 md:text-4xl">
                        <span class="block">Want light tinting?</span>
                        <span class="block" :class="textbrand600">Call for prices</span>
                    </h2>
                    <div class="mt-8 flex lg:mt-0 lg:flex-shrink-0">
                        <div class="inline-flex rounded-md shadow">
                        <a :href="`tel:${tel}`" class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white" :class="`${bgbrand600} hover:${bgbrand700}`">
                                {{number}}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import configSetup from '@/setup.js'
export default {
    setup() {
        const {textbrand600, bgbrand700, bgbrand600, textbrand900, number, tel} = configSetup()
        return {textbrand600, bgbrand700, bgbrand600, textbrand900, number, tel}
    }

}
</script>