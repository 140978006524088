<template>
  <div class="py-16 bg-gray-900 overflow-hidden lg:py-24">
    <div class="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
      <svg class="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4" width="404" height="784" fill="none" viewBox="0 0 404 784" aria-hidden="true">
        <defs>
          <pattern id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
            <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
          </pattern>
        </defs>
        <rect width="404" height="784" fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)" />
      </svg>

      <div class="relative">
        <h2 class="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-50 sm:text-4xl">
          Why Trust {{company}}?
        </h2>
        <p class="mt-4 max-w-3xl mx-auto text-center text-xl text-gray-200">
          {{company}} provides your vehicle with safety, security and most importantly style... let's not kid ourselves here! Style is really why any of us does it. But we offer a little more...
        </p>
      </div>

      <div class="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
        <div class="relative">

          <dl class="mt-10 space-y-10">
            <div v-for="item in transferFeatures" :key="item.id" class="relative">
              <dt>
                <div class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-gray-200 text-white">
                    <img loading="lazy" :src="item.image" :alt="item.name">
                </div>
                <p class="ml-16 text-lg leading-6 font-medium text-gray-50">{{ item.name }}</p>
              </dt>
              <dd class="mt-2 ml-16 text-base text-gray-200">
                {{ item.description }}
              </dd>
            </div>
          </dl>
        </div>

        <div class="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
          <svg class="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden" width="784" height="404" fill="none" viewBox="0 0 784 404">
            <defs>
              <pattern id="ca9667ae-9f92-4be7-abcb-9e3d727f2941" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                <rect x="0" y="0" width="4" height="4" :class="textbrand600" fill="currentColor" />
              </pattern>
            </defs>
            <rect width="784" height="404" fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)" />
          </svg>
          <img v-if="homeImg" loading="lazy" class="relative mx-auto rounded-lg" width="490" :src="`${homeImg}`" :alt="`${company}`" />
        </div>
      </div>

      <svg class="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12" width="404" height="784" fill="none" viewBox="0 0 404 784" aria-hidden="true">
        <defs>
          <pattern id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
            <rect x="0" y="0" width="4" height="4" :class="textbrand200" fill="currentColor" />
          </pattern>
        </defs>
        <rect width="404" height="784" fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
      </svg>
    </div>
  </div>
</template>

<script>
import { AnnotationIcon, GlobeAltIcon, LightningBoltIcon, MailIcon, ScaleIcon } from '@heroicons/vue/outline'
import configSetup from '@/setup.js'

const transferFeatures = [
  {
    id: 1,
    name: 'PROFESSIONAL SERVICE',
    description:
      'All window tints are applied using advanced technology for a flawless finish.',
    image: 'https://firebasestorage.googleapis.com/v0/b/s3-tint.appspot.com/o/Icons%2FlifetimeWarranty.png?alt=media&token=ad64fdab-c438-41f7-bc29-a07b630e0679'
  },
  {
    id: 2,
    name: 'COMPETITIVE PRICES',
    description:
      'We’re committed to providing high-quality services at competitive prices.',
    image: 'https://firebasestorage.googleapis.com/v0/b/s3-tint.appspot.com/o/Icons%2FcompetivePrices.png?alt=media&token=96fdd5b6-d176-48af-8552-8c1e58623cb6'
  },
  {
    id: 3,
    name: 'COMPLETE TRANSPARENCY',
    description:
      'We make sure our customers know exactly what they’re paying for.',
    image: 'https://firebasestorage.googleapis.com/v0/b/s3-tint.appspot.com/o/Icons%2Fdisplay.png?alt=media&token=ffa571e6-6e6c-4fd8-ba62-b484bfdc3c04'
  },
]


export default {
  setup() {
    const {theme, brand, company, homeImg, textbrand200, textbrand600} = configSetup()
    return {
      transferFeatures,
      theme,
      brand,
      company,
      homeImg,
      textbrand200, 
      textbrand600
    }
  },
}
</script>