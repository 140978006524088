<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="bg-gray-50">
    <div class="relative overflow-hidden">
      <div class="absolute inset-y-0 h-full w-full" aria-hidden="true">
        <div class="relative h-full">
          <svg class="absolute right-full transform translate-y-1/3 translate-x-1/4 md:translate-y-1/2 sm:translate-x-1/2 lg:translate-x-full" width="404" height="784" fill="none" viewBox="0 0 404 784">
            <defs>
              <pattern id="e229dbec-10e9-49ee-8ec3-0286ca089edf" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width="404" height="784" fill="url(#e229dbec-10e9-49ee-8ec3-0286ca089edf)" />
          </svg>
          <svg class="absolute left-full transform -translate-y-3/4 -translate-x-1/4 sm:-translate-x-1/2 md:-translate-y-1/2 lg:-translate-x-3/4" width="404" height="784" fill="none" viewBox="0 0 404 784">
            <defs>
              <pattern id="d2a68204-c383-44b1-b99f-42ccff4e5365" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width="404" height="784" fill="url(#d2a68204-c383-44b1-b99f-42ccff4e5365)" />
          </svg>
        </div>
      </div>

      <div class="relative pt-6 pb-16 sm:pb-24">

        <div class="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6">
          <div class="text-center">
            <h1 class="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
              <span class="block">Privacy at home</span>
              <span class="block" :class="textbrand600">or work</span>
            </h1>
            <p class="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
              If you're looking for security, privacy or to cool your building. Get some tint on your home or business windows.
            </p>
          </div>
        </div>
      </div>

      <div class="relative">
        <div class="max-w-7xl mx-auto px-4 sm:px-6">
          <img class="w-full h-96 object-center object-cover rounded" src="https://firebasestorage.googleapis.com/v0/b/s3-tint.appspot.com/o/S3%20Assets%2Falejandra-cifre-gonzalez-ylyn5r4vxcA-unsplash.jpg?alt=media&token=7f0db380-d273-4cae-9c01-bc6e4755002e" alt="Picture of home by Alejandra Gonzalez" loading="lazy"/>
        </div>
      </div>
    </div>
    <div class="bg-gray-50">
        <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-24 lg:px-8 lg:flex lg:items-center lg:justify-between">
            <h2 class="text-3xl font-extrabold tracking-tight text-gray-900 md:text-4xl">
                <span class="block">Looking for architectural film installation?</span>
                <span class="block" :class="textbrand600">Call for prices</span>
            </h2>
            <div class="mt-8 flex lg:mt-0 lg:flex-shrink-0">
                <div class="inline-flex rounded-md shadow">
                <a :href="`tel:${tel}`" class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white" :class="`${bgbrand600} hover:${bgbrand700}`">
                        {{number}}
                    </a>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import configSetup from '@/setup.js'

export default {
  components: {
  },
  setup() {
    const {textbrand600, bgbrand600, bgbrand700, number, tel} = configSetup()
    return {
      textbrand600, bgbrand600, bgbrand700, number, tel
    }
  },
}
</script>