 <template>
  <div :class="theme">
    <div class="min-h-screen bg-white dark:bg-black">
      <main>
        <!-- page hero -->
        <div>
          <!-- Hero card -->
          <div class="relative">
            <div class="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100" />
            <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
              <div class="relative shadow-xl sm:rounded-b-2xl sm:overflow-hidden">
                <div class="absolute inset-0">
                  <img class="h-full w-full object-cover" loading="lazy" src="https://firebasestorage.googleapis.com/v0/b/s3-tint.appspot.com/o/S3%20Assets%2Fg80bg-min.jpg?alt=media&token=fc481331-98ab-4c00-8bcb-e451e22b07cf" alt="Image of car" />
                  <div class="absolute inset-0 bg-gray-800 mix-blend-multiply" />
                </div>
                <div class="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
                  <h1 class="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
                    <span class="block text-white">Professional </span>
                    <span class="block" :class="textbrand200">Window Tinting Service</span>
                  </h1>
                  <p class="mt-6 max-w-lg mx-auto text-center text-xl sm:max-w-3xl" :class="textbrand100">
                    Premium Window Film Installations For Automotive Vehicles
                  </p>
                  <div class="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
                    <div class="space-y-4 sm:space-y-0 sm:mx-auto sm:inline-grid sm:grid-cols-2 sm:gap-5">
                      <router-link :to="{ name: 'Tinting'}" class="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm bg-white sm:px-8" :class="textbrand700" @click="btnClick('instant_quote_HOME', 'label_home')">
                        Instant Quote
                      </router-link>
                      <router-link :to="{ name: 'Booking' }" class="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white  bg-opacity-60 hover:bg-opacity-70 sm:px-8" :class="bgbrand500" @click="btnClick('booking_HOME', 'label_home')">Booking</router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Logo cloud -->
          <div class="bg-gray-100">
            <div class="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
              <p class="text-center text-sm font-semibold uppercase text-gray-700 tracking-wide">
                Professional installations using premium products
              </p>
              <div class="mt-6 grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-5">
                <div class="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                  <img class="h-12" loading="lazy" src="https://globalwindowfilms.com/wp-content/uploads/2020/12/globalwinoowfilms-logo.png" alt="Global Window Films" />
                </div>
                <div class="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                  <img class="h-12" loading="lazy" src="https://logos-download.com/wp-content/uploads/2016/12/Avery_Dennison_logo_logotype.png" alt="Avery Dennison" />
                </div>
                <div class="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                  <img class="h-12" loading="lazy" src="https://www.phantomautos.com/wp-content/uploads/2014/12/logo-hexis.png" alt="Hexis" />
                </div>
  
              </div>
            </div>
          </div>
                  <div class="bg-white">
            <div aria-hidden="true" class="relative">
              <img loading="lazy" src="https://firebasestorage.googleapis.com/v0/b/s3-tint.appspot.com/o/Marketing%20Photos%2Fm_adils-photography-dpxPWMn9ygc-unsplash.jpg?alt=media&token=a52f2da3-88c6-40b3-a848-f0c3c06517e8" alt="" class="w-full h-96 object-center object-cover" />
              <div class="absolute inset-0 bg-gradient-to-t from-white" />
            </div>

            <div class="relative -mt-12 max-w-7xl mx-auto pb-16 px-4 sm:pb-24 sm:px-6 lg:px-8">
              <div class="max-w-2xl mx-auto text-center lg:max-w-4xl">
                <h2 class="text-3xl font-extrabold tracking-tight sm:text-4xl" :class="textbrand900">Get a professional tint and experience fom one of {{city}}'s Best Tinters</h2>
                <p class="mt-4 text-gray-500">{{description}}</p>
              </div>

              <dl class="mt-16 max-w-2xl mx-auto grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:max-w-none lg:grid-cols-3 lg:gap-x-8">
                <div v-for="feature in features" :key="feature.name" class="border-t border-gray-200 pt-4">
                  <component :is="feature.icon" class="h-6 w-6" aria-hidden="true" />
                  <dt class="font-medium text-gray-900">{{ feature.name }}</dt>
                  <dd class="mt-2 text-sm text-gray-500">{{ feature.description }}</dd>
                </div>
              </dl>
            </div>
          </div>
          <div class="bg-gray-50">
            <div class="max-w-2xl mx-auto px-4 py-10 sm:px-6 sm:py-32 lg:max-w-7xl lg:px-8">
              <!-- Details section -->
              <section aria-labelledby="details-heading">
                <div class="flex flex-col items-center text-center">
                  <h2 id="details-heading" class="text-3xl font-extrabold tracking-tight sm:text-4xl" :class="textbrand900">Do it for the Style</h2>
                  <p class="mt-3 max-w-3xl text-lg text-gray-600">In our experience, the number 1 reason anyone tints their car is for the amazing upgrade in the car's appearance.</p>
                </div>
                <div class="mt-16 grid grid-cols-1 gap-y-16 lg:grid-cols-2 lg:gap-x-8">
                  <div>
                    <div class="w-full aspect-h-2 rounded-lg overflow-hidden">
                      <img loading="lazy" src="https://firebasestorage.googleapis.com/v0/b/s3-tint.appspot.com/o/Marketing%20Photos%2Fm_serge-kutuzov-nm_UwlzQe_Q-unsplash.jpg?alt=media&token=e2c10ce7-de4f-4fc3-a9f9-74daadb82351" alt="Drawstring top with elastic loop closure and textured interior padding." class="w-full h-full object-center object-cover" />
                    </div>
                    <p class="mt-8 text-base text-gray-500">Whether you drive a pinto or a top of the line exotic car, don't settle for the fish bowl effect, add some style to your ride.</p>
                  </div>
                  <div>
                    <div class="w-full aspect-w-2 aspect-h-2 rounded-lg overflow-hidden">
                      <img loading="lazy" src="https://firebasestorage.googleapis.com/v0/b/s3-tint.appspot.com/o/Marketing%20Photos%2Fm_serge-kutuzov-TNybYN-LqJo-unsplash.jpg?alt=media&token=3f4a029e-ad81-4d28-91ec-9a133325b390" alt="Front zipper pouch with included key ring." class="w-full h-full object-center object-cover" />
                    </div>
                    <p class="mt-8 text-base text-gray-500">Old car, new car, used car... who's car doesn't need a tint!? Get the very best tint film and installation for a very reasonable price!</p>
                  </div>
                  <div class="bg-gray-50">
                      <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-24 lg:px-8 lg:flex lg:items-center lg:justify-between">
                          <h2 class="text-3xl font-extrabold tracking-tight text-gray-900 md:text-4xl">
                              <span class="block">Searching for fair automotive tint pricing?</span>
                              <span class="block" :class="textbrand600">See our prices</span>
                          </h2>
                          <div class="mt-4 flex lg:mt-0 lg:flex-shrink-0">
                              <div class="inline-flex rounded-md shadow">
                              <router-link :to="{name: 'Tinting'}"
                              class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white" :class="`${bgbrand600} hover:${bgbrand700}`">
                              Pricing
                              </router-link>
                              </div>
                          </div>
                      </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
        <FlatGlass v-if="flatGlassEnabled"/>
        <LightTint v-if="lightEnabled"/>

        <!-- More main page content here... -->

        <div class="bg-gray-50 overflow-hidden">
          <div class="relative max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
            <svg class="absolute top-0 left-full transform -translate-x-1/2 -translate-y-3/4 lg:left-auto lg:right-full lg:translate-x-2/3 lg:translate-y-1/4" width="404" height="784" fill="none" viewBox="0 0 404 784" aria-hidden="true">
              <defs>
                <pattern id="8b1b5f72-e944-4457-af67-0c6d15a99f38" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                  <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width="404" height="784" fill="url(#8b1b5f72-e944-4457-af67-0c6d15a99f38)" />
            </svg>
          </div>
        </div>
        <!-- Second feature list -->
        <SecondFeatures loading="lazy"/>
        <CTA loading="lazy"/>
        <ContactLocation loading="lazy" />

      </main>
    </div>
  </div>
</template>

<script>
import SecondFeatures from '@/components/SecondFeatureList'
import CTA from '@/components/CTA'
import ContactLocation from '@/components/ContactLocation'
import LightTint from '@/components/LightTint'
import FlatGlass from '@/components/FlatGlass/FlatGlass'
import configSetup from '@/setup.js'
import { 
LightningBoltIcon,
ScaleIcon,
CalendarIcon
} from '@heroicons/vue/outline'
import {event} from 'vue-gtag'





export default {
  created() {
    const {company} = configSetup()
    document.title = `${company} | Home`
    
},
  components: {
    SecondFeatures,
    CTA,
    ContactLocation,
    LightTint,
    FlatGlass

  },
  setup() {
    const {
      theme, company, geo,
      textbrand100,
      textbrand200,
      textbrand600,
      textbrand700,
      textbrand900,
      bgbrand500,
      bgbrand600,
      bgbrand700,
      country,
      city,
      description,
      lightEnabled,
      flatGlassEnabled
      } = configSetup()

      const btnClick = (btnName, label) => {
        event(btnName, {
          'event_category': btnName,
          'event_label': label
          })
      }

    

    const features = [
  {
    name: 'Lifetime Warranty',
    description: 'Enjoy peace of mind with lifetime warranty offered on all the products we use.',
    icon: <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
</svg>
  },
  {
    name: 'Registered',
    description:
      `${company} is a registerd business with the municipal government`,
    icon: ScaleIcon,
  },
  {
    name: 'Fully Insured',
    description:
      'Leave your vehicle with us and have confidence that we are insured and covered.',
    icon: LightningBoltIcon,
  },
  {
    name: 'Streamlined Quote and Booking',
    description:
      'Conveniently and easily get a quote, and book whatever day and time that works best with your schedule.' ,
    icon: CalendarIcon,
  },
]

    return {
      features,
      SecondFeatures,
      CTA,
      ContactLocation,
      theme,
      geo,
      country,
      textbrand100,
      textbrand200,
      textbrand600,
      textbrand700,
      textbrand900,
      bgbrand500,
      bgbrand600,
      bgbrand700,
      btnClick,
      city,
      description,
      lightEnabled,
      flatGlassEnabled
    }
  },
}
</script>